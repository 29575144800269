
import { UserModule } from '@/store/user';
import { IMyHomepageDetail, IMyHomepagePost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageBoardInquireList',
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private postList!: IMyHomepagePost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ required: true }) private listTotal!: number

  get isAdmin() {
    return UserModule.userId === this.detail.userDetail.userId;
  }

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.listTotal - totalSize - index;
  }
}
