
import { IMyHomepagePost } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageBoardNoticeList',
})
export default class extends Vue {
  @Prop({ required: true }) private postList!: IMyHomepagePost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ required: true }) private listTotal!: number

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.listTotal - totalSize - index;
  }
}
