
import { getBoard } from '@/api/myHomepageBoard';
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import HomepageBoardIndex from '@/components/homepage/board/index.vue';

@Component({
  name: 'HomepageBoard',
  components: {
    HomepageBoardIndex,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail

  @Watch('$route.params.boardUid')
  private handleChangeBoard() {
    this.getBoard();
  }

  mounted() {
    this.getBoard();
  }

  private board: IMyHomepageBoard | null = null;

  private getBoard() {
    getBoard(this.$route.params.boardUid).then((res) => {
      this.board = res.data;
    });
  }
}
