
import { IMyHomepageBoard, IMyHomepageDetail, IMyHomepagePost } from '@/types';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { getHomepagePostList } from '@/api/myHomepagePost';
import BoardPagination from '@/components/board/pagination.vue';
import NoticeList from './notice/list.vue';
import InquireList from './inquire/list.vue';
import ProductList from './product/list.vue';

@Component({
  name: 'HomepageBoardIndex',
  components: {
    NoticeList,
    InquireList,
    ProductList,
    BoardPagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private board!: IMyHomepageBoard

  @Watch('board')
  private handleChangeBoard() {
    this.getPostList();
  }

  get hasWriteAuth() {
    return UserModule.userId === this.detail.userDetail.userId || this.board.secretState;
  }

  mounted() {
    this.getPostList();
  }

  private listTotal = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: this.board.listSize,
    searchType: 'title',
    searchValue: '',
    boardUid: '',
  };

  private postList: IMyHomepagePost[] = [];

  private getPostList() {
    this.listQuery.boardUid = this.$route.params.boardUid;
    getHomepagePostList(this.listQuery).then((res) => {
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      this.postList = res.data.content;
    });
  }

  private handleChangePaging(page: number) {
    this.listQuery.page = page;
    this.getPostList();
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getPostList();
  }
}
